import React, { useRef } from "react";
import ListPesan2 from "../../components/ListPesan/ListPesan2";
import Slider from "react-slick";
import Box from "@mui/material/Box";
import LCM5 from "../../assets/images/display/lcm-5.jpg";
import LCM2 from "../../assets/images/display/lcm-2.jpg";
import LCM3 from "../../assets/images/display/lcm-3.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CustomerDisplay.css";
import Stack from "@mui/material/Stack";
import { ImageListItemProps } from "@mui/material";

const IMAGES = [{ src: LCM5 }, { src: LCM5 }, { src: LCM5 }];

const CustomerDisplay = () => {
    const ref = useRef<ImageListItemProps>(null);

    return (
        <Stack direction="row" height="100vh">
            <Box height="100%" className="slider-container">
                <Slider
                    className="custom-slick"
                    infinite={true}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    autoplaySpeed={5000}
                    autoplay
                >
                    {IMAGES.map((li, idx) => (
                        <Box
                            key={idx.toString()}
                            component="img"
                            src={li.src}
                            alt="photo"
                            width="100%"
                            height="100vh"
                            sx={{
                                objectFit: "cover",
                            }}
                            ref={ref}
                        />
                    ))}
                </Slider>
            </Box>
            <Box
                height="100%"
                flex={1}
                sx={{
                    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
            >
                <ListPesan2 />
            </Box>
        </Stack>
        // <Grid container>
        //     <Grid item xs={12} sm={12} md={5}>
        //         <Box height="100vh">
        //             <Box>
        //                 <Slider
        //                     className="custom-slick"
        //                     // dots
        //                     infinite={true}
        //                     speed={500}
        //                     slidesToShow={1}
        //                     slidesToScroll={1}
        //                     autoplaySpeed={5000}
        //                     autoplay
        //                 >
        //                     {IMAGES.map((li, idx) => (
        //                         <Box
        //                             key={idx.toString()}
        //                             component="img"
        //                             borderRadius={2}
        //                             src={li.src}
        //                             alt="photo"
        //                             width="100%"
        //                             height="calc(100vh - 6px)"
        //                             sx={{
        //                                 objectFit: "contain",
        //                             }}
        //                         />
        //                     ))}
        //                 </Slider>
        //                 {/* <Box
        //                     display="flex"
        //                     alignItems="center"
        //                     justifyContent="center"
        //                     gap={1}
        //                 >
        //                     <Typography variant="h6" color="white" fontWeight={600}>
        //                         Powered by ukmlokal.com
        //                     </Typography>
        //                 </Box> */}
        //             </Box>
        //         </Box>
        //     </Grid>
        //     <Grid
        //         id="barangPesanan"
        //         item
        //         xs={12}
        //         sm={12}
        //         md={7}
        //         sx={{
        //             borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
        //             borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        //         }}
        //     >
        //         <Box height="100vh">
        //             <ListPesan2 />
        //         </Box>
        //     </Grid>
        // </Grid>
    );
};
export default CustomerDisplay;
